import React, { Component } from 'react'
import SeoHead from 'Components/SeoHead'
import Layout from 'Components/Layout'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Img from 'Components/UI/Img'
import Link from 'Components/Utils/Link'
import LazyLoad from 'react-lazyload'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import styles from './index.module.scss'
import { navigate } from 'gatsby'

const placeSrc = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0NFRjc5NkZBQjgxMTFFODkyMTY5RTRBOEY0REEzNjMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0NFRjc5NzBBQjgxMTFFODkyMTY5RTRBOEY0REEzNjMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3Q0VGNzk2REFCODExMUU4OTIxNjlFNEE4RjREQTM2MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3Q0VGNzk2RUFCODExMUU4OTIxNjlFNEE4RjREQTM2MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqmjhtcAAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC`
const lazyLoadHeight = 400
const lazyLoadOffset = 300

class RedPage extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  didImgLoad = ({el}) => {
    el.classList.add(styles.imageShow)
  }
  render() {
    const backgroundColor = this.props.pageContext.backgroundColor
    const placeholder = <img style={{minHeight: lazyLoadHeight}} src={placeSrc}/>
    return (
      <Layout>
        <SeoHead
          title={`${this.props.pageContext.title}`}
          path={`/${this.props.pageContext.slug}`}
          description={`Stella Luna is proud to present a special collaboration with (RED), the non-profit organization that raises funds and awareness to help end AIDS.`}
        />
        <div style={{backgroundColor}} className={styles.parent}>
          <h1>Every generation is known for something. <br/>At Stella Luna, we want to be part of the generation known for helping to end AIDS.</h1>
          <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
            <Img
              didLoad={this.didImgLoad}
              className={`${styles.image} u-col-12`}
              src={`images/RED/2019-11-12-STELLALUNARED17234.jpg`}
              srcRetina={`images/RED/2019-11-12-STELLALUNARED17234@2x.jpg`}
              noRetinaOnMobile={true}
            />
          </LazyLoad>
          <p>Stella Luna is proud to present a special collaboration with (RED), the non-profit organization that raises funds and awareness to help end AIDS. Every (STELLA LUNA)<span className={styles.super}>RED</span> purchase contributes directly to (RED)’s fight to end AIDS through the Global Fund. (RED) funded grands support a wide range of life-saving programs in sub-Saharan Africa.</p>
          <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
            <Link to={`/ss20/red/stella-luna-x-red-lucien-lace-up`} className={`u-row`}>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-4`}
                src={`images/RED/2019-11-12-STELLALUNARED16919.jpg`}
                srcRetina={`images/RED/2019-11-12-STELLALUNARED16919@2x.jpg`}
                noRetinaOnMobile={true}
              />
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-8`}
                src={`images/RED/2019-11-12-STELLALUNARED17307.jpg`}
                srcRetina={`images/RED/2019-11-12-STELLALUNARED17307@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </Link>
          </LazyLoad>
          <Link to={`/ss20/red/stella-luna-x-red-lucien-lace-up`} className={`u-row ${styles.lucienBlockWrapper}`}>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-4 ${styles.noMobile}`}
                src={`images/RED/lucien-graphic-block.jpg`}
                srcRetina={`images/RED/lucien-graphic-block@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-6 u-col-lg-4`}
                src={`images/RED/lucien-title-block.jpg`}
                srcRetina={`images/RED/lucien-title-block@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-6 u-col-lg-4`}
                src={`images/RED/lucien-img-block.jpg`}
                srcRetina={`images/RED/lucien-img-block@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
          </Link>
          <div className={`u-separator t-bg-white`}/>
          <p>
            The special-edition (STELLA LUNA)<span className={styles.super}>RED</span> Lucien Lace-Up relies on an abundance of advanced techniques and combinations, to create one singularly sporty, stylish and comfortable whole.<br/>
            <BackgroundBtn
              onClick={() => { navigate(`/ss20/red/stella-luna-x-red-lucien-lace-up`) }}
              className={`u-col-lg-4 u-mrg-t--10 ${styles.buyBtn}`}
              text='Show More'
              fullWhite
              highlighted
            />
          </p>
          <div className={`u-separator t-bg-white`}/>
          <div className={`u-row ${styles.diptyqueWrapper}`}>
            <div className={`u-col-12 u-col-lg-6`}>
              <img className={styles.stellaRedLogo} src={`/images/RED/logo.svg`}/>
            </div>
            <div className={`u-col-12 u-col-lg-6`}>
              <p>When you buy a (STELLA LUNA)<span className={styles.super}>RED</span> shoe, your purchase triggers a donation to the Global Fund. 100% of the funds raised will go directly to work on the ground in sub-Saharan Africa, focusing on countries with a high prevalence of HIV.</p>
            </div>
          </div>
          <Link to={`/ss20/red/stella-luna-x-red-crystal-chain-sandal`} className={`u-row`}>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-8`}
                src={`images/RED/2019-11-12-STELLALUNARED16784R.jpg`}
                srcRetina={`images/RED/2019-11-12-STELLALUNARED16784R@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-4`}
                src={`images/RED/2019-11-12-STELLALUNARED17404R.jpg`}
                srcRetina={`images/RED/2019-11-12-STELLALUNARED17404R@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
          </Link>
          <Link to={`/ss20/red/stella-luna-x-red-crystal-chain-sandal`} className={`u-row ${styles.sandalBlockWrapper}`}>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-6 u-col-lg-4`}
                src={`images/RED/sandal-img-block.jpg`}
                srcRetina={`images/RED/sandal-img-block@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-6 u-col-lg-4`}
                src={`images/RED/sandal-title-block.jpg`}
                srcRetina={`images/RED/sandal-title-block@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
            <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
              <Img
                didLoad={this.didImgLoad}
                className={`${styles.image} u-col-4 ${styles.noMobile}`}
                src={`images/RED/sandal-graphic-block.jpg`}
                srcRetina={`images/RED/sandal-graphic-block@2x.jpg`}
                noRetinaOnMobile={true}
              />
            </LazyLoad>
          </Link>
          <div className={`u-separator t-bg-white`}/>
          <p>
            For those who’d like a little extra sparkle for fall—(and, really, who wouldn’t?)— we’ve introducing a special-edition (STELLA LUNA)<span className={styles.super}>RED</span> Crystal Chain Sandal for this holiday season. To ensure that our signature XXL Chains are as eye-catching as possible, we’ve covered them in sparkling strass and set them atop a rich red velvet.<br/>
            <BackgroundBtn
              onClick={() => { navigate(`/ss20/red/stella-luna-x-red-crystal-chain-sandal`) }}
              className={`u-col-lg-4 u-mrg-t--10 ${styles.buyBtn}`}
              text='Show More'
              fullWhite
              highlighted
            />
          </p>
          <div className={`u-separator t-bg-white`}/>
          <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
            <div className={`u-row ${styles.counterWrapper}`}>
              <div className={`u-col-2 ${styles.noBorder}`}>
                <Img
                  didLoad={this.didImgLoad}
                  className={styles.image}
                  src={`images/RED/block-1.jpg`}
                  srcRetina={`images/RED/block-1@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </div>
              <div className={`u-col-2`}>
                <Img
                  didLoad={this.didImgLoad}
                  className={styles.image}
                  src={`images/RED/block-2.jpg`}
                  srcRetina={`images/RED/block-2@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </div>
              <div className={`u-col-2`}>
                <Img
                  didLoad={this.didImgLoad}
                  className={styles.image}
                  src={`images/RED/block-3.jpg`}
                  srcRetina={`images/RED/block-3@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </div>
              <div className={`u-col-2`}>
                <Img
                  didLoad={this.didImgLoad}
                  className={styles.image}
                  src={`images/RED/block-go-1.jpg`}
                  srcRetina={`images/RED/block-go-1@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </div>
              <div className={`u-col-2`}>
                <Img
                  didLoad={this.didImgLoad}
                  className={styles.image}
                  src={`images/RED/block-go-2.jpg`}
                  srcRetina={`images/RED/block-go-2@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </div>
              <div className={`u-col-2`}>
                <Img
                  didLoad={this.didImgLoad}
                  className={styles.image}
                  src={`images/RED/block-go-3.jpg`}
                  srcRetina={`images/RED/block-go-3@2x.jpg`}
                  noRetinaOnMobile={true}
                />
              </div>
            </div>
          </LazyLoad>
          <LazyLoad placeholder={placeholder} height={lazyLoadHeight} offset={lazyLoadOffset} once>
            <Img
              didLoad={this.didImgLoad}
              className={`${styles.image} u-col-12`}
              src={`images/RED/2019-11-12-STELLALUNARED16906.jpg`}
              srcRetina={`images/RED/2019-11-12-STELLALUNARED16906@2x.jpg`}
              noRetinaOnMobile={true}
            />
          </LazyLoad>
          <p>Every day, over 400 babies are born with HIV—and, at Stella Luna, we’ve signed on to (RED)’s commitment to get that number down to zero. Today, life-saving medication, costing just 20 cents a day, exists to stop mother-to-child transmission and ensure that children are born HIV-free. Our two special-edition designs will help raise funds in support of (RED)’s fight against AIDS.</p>
        </div>
      </Layout>
    )
  }
}

export default RedPage
